import { createApi } from '@reduxjs/toolkit/dist/query/react';
import B2ChatClient, { apiResource, b2chatBaseQuery } from '@src/client';

export type SummarizeChatResponse = { content: string };
export type SummarizeChatRequest = { chatId: string };

export const chatServicesApi = createApi({
  reducerPath: 'chatServices',
  baseQuery: b2chatBaseQuery,
  tagTypes: ['summarize-chat'],
  endpoints: ({ query }) => ({
    summarizeChat: query<SummarizeChatResponse, SummarizeChatRequest>({
      providesTags: ['summarize-chat'],
      query: request =>
        apiResource(B2ChatClient.resources.servicesChats.actions.summarize, {
          params: request,
        }),
    }),
  }),
});

export const { useLazySummarizeChatQuery } = chatServicesApi;
