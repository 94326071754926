import B2ChatClient from '@client-sdk';
import {
  fetchActiveChatQuickMessages,
  fetchActiveChatQuickMessagesFailure,
  fetchActiveChatQuickMessagesFulfill,
  fetchActiveChatQuickMessagesSuccess,
} from '@reducers/activeChatQuickMessages';
import {
  fetchWaAccounts,
  fetchWaAccountsFailure,
  fetchWaAccountsFulfill,
  fetchWaAccountsSuccess,
} from '@reducers/userWaAccounts';
import { departmentItemByIdSelector } from '@selectors/departments';
import { activeChatSelector } from '@src/selectors/chats';
import { ChatTray } from '@src/types/chats';
import { Department, ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, put, select, takeLatest } from 'redux-saga/effects';

function* fetchActiveChatQuickMessageListSaga() {
  try {
    const activeChat: ChatTray = yield select(activeChatSelector);
    const standardBotId = activeChat.botId;
    const departmentId = activeChat?.businessProcessNode?.id;

    if (!standardBotId) return;

    // fetching general quick messages
    if (standardBotId) {
      const response: B2ChatAPI.Response<B2ChatAPI.QuickMessages.Retrieve> =
        yield call([B2ChatClient.resources.quickMessage.actions, 'retrieve'], {
          params: { id: standardBotId },
        });

      if (response.error) {
        yield put(fetchActiveChatQuickMessagesFailure());
      } else {
        yield put(
          fetchActiveChatQuickMessagesSuccess({
            generalMessages: {
              botId: Number(standardBotId),
              items: response.data || [],
            },
          })
        );
      }
    }

    if (!departmentId) return;

    const department: Department = yield select(state =>
      departmentItemByIdSelector(state)(departmentId)
    );

    // fetching quick messages of current department
    if (department) {
      const response: B2ChatAPI.Response<B2ChatAPI.QuickMessages.Retrieve> =
        yield call([B2ChatClient.resources.quickMessage.actions, 'retrieve'], {
          params: { id: department.botId },
        });

      if (response.error) {
        yield put(fetchActiveChatQuickMessagesFailure());
      } else {
        yield put(
          fetchActiveChatQuickMessagesSuccess({
            currentMessages: {
              botId: department.botId,
              department: department.name,
              items: response.data || [],
            },
          })
        );
      }
    }

    const parent: Department = yield select(state =>
      departmentItemByIdSelector(state)(department?.parentNodeId as number)
    );

    if (parent) {
      const response: B2ChatAPI.Response<B2ChatAPI.QuickMessages.Retrieve> =
        yield call([B2ChatClient.resources.quickMessage.actions, 'retrieve'], {
          params: { id: parent.botId },
        });

      if (response.error) {
        yield put(fetchActiveChatQuickMessagesFailure());
      } else {
        yield put(
          fetchActiveChatQuickMessagesSuccess({
            inheritMessages: {
              botId: parent.botId,
              department: parent.name,
              items: response.data || [],
            },
          })
        );
      }
    }
  } catch {
    yield put(fetchActiveChatQuickMessagesFailure());
  } finally {
    yield put(fetchActiveChatQuickMessagesFulfill());
  }
}

function* fetchWaAccountsSaga() {
  try {
    const response = (yield call(
      B2ChatClient.resources.botServices.actions.listUserWaAccounts
    )) as B2ChatAPI.Response<B2ChatAPI.BotServices.List>;

    if (response.error != null) {
      yield put(fetchWaAccountsFailure(response.error));
    } else {
      yield put(fetchWaAccountsSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchWaAccountsFailure(error as ErrorData));
  } finally {
    yield put(fetchWaAccountsFulfill());
  }
}

function* userWaAccountsSaga() {
  yield takeLatest(
    fetchActiveChatQuickMessages,
    fetchActiveChatQuickMessageListSaga
  );
  yield takeLatest(fetchWaAccounts, fetchWaAccountsSaga);
}

export default userWaAccountsSaga;
