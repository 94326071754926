import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ResponsesByFlowToken,
  WhasAppFlowItem,
  WhatsAppFlowResponsesFilters,
  WhatsAppFlowsFilters,
} from '@src/components/AdminHome/Channels/WhatsApp/WhatsAppFlows/types';
import type { ErrorData } from '@types';

const LIMIT_PER_PAGE_FLOWS = '20';
const LIMIT_PER_PAGE_RESPONSES = '25';

type Loading =
  | 'fetch-ws-flows'
  | 'fetch-ws-flows-response'
  | 'fetch-ws-flows-screens'
  | 'fetch-ws-flows-response-by-flow-token'
  | 'fetch-ws-flows-responses-export';

export type WsFlowsState = {
  error: ErrorData | null;
  loading: Loading[];
  items: WhasAppFlowItem[];
  responses: FetchWsFlowsResponseSuccess | null;
  screens: string[];
  filters: WhatsAppFlowsFilters;
  flowResponsesFilters: WhatsAppFlowResponsesFilters;
  selectedFlowId: string;
  responsesByFlowToken: ResponsesByFlowToken | null;
  exportMessage: string;
};

type WsFlowsReducer<P = void> = (
  state: WsFlowsState,
  action: PayloadAction<P>
) => void;

const initialState: WsFlowsState = {
  error: null,
  loading: [],
  items: [],
  responses: null,
  screens: [],
  filters: {
    phoneNumber: '',
    status: undefined,
    limit: LIMIT_PER_PAGE_FLOWS,
    offset: '0',
  },
  flowResponsesFilters: {
    start_time: '',
    end_time: '',
    limit: LIMIT_PER_PAGE_RESPONSES,
    offset: '0',
  },
  selectedFlowId: '',
  responsesByFlowToken: null,
  exportMessage: '',
};

export type WsFlowsResponse = {
  data: WhasAppFlowItem[];
};

const handleFetchWsFlows: WsFlowsReducer = state => {
  state.loading.push('fetch-ws-flows');
  state.error = null;
};

const handleFetchWsFlowsSuccess: WsFlowsReducer<WsFlowsResponse> = (
  state,
  action
) => {
  state.items = action.payload.data;
};

const handleFetchWsFlowsFailure: WsFlowsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
  state.items = [];
};

const handleFetchWsFlowsFulfill: WsFlowsReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-ws-flows');
};

export type FetchWsFlowsResponse = {
  flowId: string;
};

const handleFetchWsFlowsResponse: WsFlowsReducer = state => {
  state.loading.push('fetch-ws-flows-response');
  state.error = null;
};
export type WsFlowResponseDataItemRes = {
  label: string;
  value: unknown;
};
export type WsFlowResponseDataItem = {
  // eslint-disable-next-line camelcase
  contact_phone_number: string;
  // eslint-disable-next-line camelcase
  template_name: string;
  // eslint-disable-next-line camelcase
  campaign_name: string;
  response: WsFlowResponseDataItemRes[];
};

export type FetchWsFlowsResponseSuccess = {
  labels: string[];
  data: WsFlowResponseDataItem[];
};

const handleFetchWsFlowsResponseSuccess: WsFlowsReducer<
  FetchWsFlowsResponseSuccess
> = (state, action) => {
  state.responses = action.payload;
};

const handleFetchWsFlowsResponseFailure: WsFlowsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchWsFlowsResponseFulfill: WsFlowsReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-ws-flows-response');
};

const handleFetchWsFlowsScreens: WsFlowsReducer<
  FetchWsFlowsResponse
> = state => {
  state.loading.push('fetch-ws-flows-screens');
  state.error = null;
};

const handleFetchWsFlowsScreensSuccess: WsFlowsReducer<string[]> = (
  state,
  action
) => {
  state.screens = action.payload;
};

const handleFetchWsFlowsScreensFailure: WsFlowsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchWsFlowsScreensFulfill: WsFlowsReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-ws-flows-screens');
};

const handleSetWsFlowsFilters: WsFlowsReducer<WhatsAppFlowsFilters> = (
  state,
  action
) => {
  state.filters = action.payload;
};

const handleSetWsFlowResponsesFilters: WsFlowsReducer<
  WhatsAppFlowResponsesFilters
> = (state, action) => {
  state.flowResponsesFilters = action.payload;
};

const handleSetWsFlowSelectedId: WsFlowsReducer<string> = (state, action) => {
  state.selectedFlowId = action.payload;
};

const handleFetchWsFlowResponsesByFlowToken: WsFlowsReducer<{
  flowToken: string;
}> = state => {
  state.loading.push('fetch-ws-flows-response-by-flow-token');
  state.error = null;
};

const handleFetchWsFlowResponsesByFlowTokenSuccess: WsFlowsReducer<
  ResponsesByFlowToken
> = (state, action) => {
  state.responsesByFlowToken = action.payload;
};

const handleFetchWsFlowResponsesByFlowTokenFailure: WsFlowsReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
};

const handleFetchWsFlowResponsesByFlowTokenFulfill: WsFlowsReducer<
  string
> = state => {
  state.loading = state.loading.filter(
    l => l !== 'fetch-ws-flows-response-by-flow-token'
  );
};

const handleCleanWsFlowsResponsesByFlowToken: WsFlowsReducer = state => {
  state.responsesByFlowToken = null;
};

export type HandleFetchWsFlowResponsesExport = {
  flowId: string;
  emails: string[];
};

const handleFetchWsFlowResponsesExport: WsFlowsReducer<
  HandleFetchWsFlowResponsesExport
> = state => {
  state.loading.push('fetch-ws-flows-responses-export');
  state.error = null;
};

const handleFetchWsFlowResponsesExportSuccess: WsFlowsReducer<{
  message: string;
}> = (state, action) => {
  state.exportMessage = action.payload.message;
};

const handleFetchWsFlowResponsesExportFailure: WsFlowsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
  state.exportMessage = 'Not found';
};

const handleFetchWsFlowResponsesExportFulfill: WsFlowsReducer = state => {
  state.loading = state.loading.filter(
    l => l !== 'fetch-ws-flows-responses-export'
  );
};

const wsFlowsSlice = createSlice({
  name: 'WsFlowsSlice',
  initialState,
  reducers: {
    fetchWsFlows: handleFetchWsFlows,
    fetchWsFlowsSuccess: handleFetchWsFlowsSuccess,
    fetchWsFlowsFailure: handleFetchWsFlowsFailure,
    fetchWsFlowsFulfill: handleFetchWsFlowsFulfill,
    fetchWsFlowsResponse: handleFetchWsFlowsResponse,
    fetchWsFlowsResponseSuccess: handleFetchWsFlowsResponseSuccess,
    fetchWsFlowsResponseFailure: handleFetchWsFlowsResponseFailure,
    fetchWsFlowsResponseFulfill: handleFetchWsFlowsResponseFulfill,
    fetchWsFlowsScreens: handleFetchWsFlowsScreens,
    fetchWsFlowsScreensSuccess: handleFetchWsFlowsScreensSuccess,
    fetchWsFlowsScreensFailure: handleFetchWsFlowsScreensFailure,
    fetchWsFlowsScreensFulfill: handleFetchWsFlowsScreensFulfill,
    setWsFlowsFilters: handleSetWsFlowsFilters,
    setWsFlowResponsesFilters: handleSetWsFlowResponsesFilters,
    setWsFlowSelectedId: handleSetWsFlowSelectedId,
    fetchWsFlowResponsesByFlowToken: handleFetchWsFlowResponsesByFlowToken,
    fetchWsFlowResponsesByFlowTokenSuccess:
      handleFetchWsFlowResponsesByFlowTokenSuccess,
    fetchWsFlowResponsesByFlowTokenFailure:
      handleFetchWsFlowResponsesByFlowTokenFailure,
    fetchWsFlowResponsesByFlowTokenFulfill:
      handleFetchWsFlowResponsesByFlowTokenFulfill,
    cleanWsFlowsResponsesByFlowToken: handleCleanWsFlowsResponsesByFlowToken,
    fetchWsFlowResponsesExport: handleFetchWsFlowResponsesExport,
    fetchWsFlowResponsesExportSuccess: handleFetchWsFlowResponsesExportSuccess,
    fetchWsFlowResponsesExportFailure: handleFetchWsFlowResponsesExportFailure,
    fetchWsFlowResponsesExportFulfill: handleFetchWsFlowResponsesExportFulfill,
  },
});

const { actions, reducer } = wsFlowsSlice;

export const {
  fetchWsFlows,
  fetchWsFlowsSuccess,
  fetchWsFlowsFailure,
  fetchWsFlowsFulfill,
  fetchWsFlowsResponse,
  fetchWsFlowsResponseSuccess,
  fetchWsFlowsResponseFailure,
  fetchWsFlowsResponseFulfill,
  fetchWsFlowsScreens,
  fetchWsFlowsScreensSuccess,
  fetchWsFlowsScreensFailure,
  fetchWsFlowsScreensFulfill,
  setWsFlowsFilters,
  setWsFlowResponsesFilters,
  setWsFlowSelectedId,
  fetchWsFlowResponsesByFlowToken,
  fetchWsFlowResponsesByFlowTokenSuccess,
  fetchWsFlowResponsesByFlowTokenFailure,
  fetchWsFlowResponsesByFlowTokenFulfill,
  cleanWsFlowsResponsesByFlowToken,
  fetchWsFlowResponsesExport,
  fetchWsFlowResponsesExportSuccess,
  fetchWsFlowResponsesExportFailure,
  fetchWsFlowResponsesExportFulfill,
} = actions;

export default reducer;
